<div [@routerTransition]>
    <div class="content">
        <div [class]="containerClass">
            <div class="card card-custom gutter-b border-top border-warning border-5 border-start-0 border-end-0 border-bottom-0">
                <div class="card-body">

                    <form class="form" autocomplete="off">
                        <div>
                            <div class="row align-items-center">

                                <div class="col-md-5 col-xl-5">
                                    <h4>
                                        <span>{{"MultiGeo" | localize}}</span>
                                    </h4>
                                </div>
                                <div class="col-xl-7 col-md-7">
                                    <div class="row float-end">
                                        <div class="col-auto">
                                            <div class="input-icon input-icon--right  float-end">
                                                <input id="t_Search" [(ngModel)]="filterText" name="filterText" #searchTextInput
                                                       class="form-control"
                                                       [placeholder]="l('SearchWithThreeDot')" type="text"
                                                       (keyup.enter)="onKeypress($event)">
                                                <span class="input-icon__icon input-icon__icon--right btn-primary" (click)="onKeypress($event)">
                                                    <span><i class="la la-search clr-white"></i></span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div class="row mr-3">
                                            <button [disabled]="primengTableHelper.totalRecordsCount === 0 ? true : false" id="b_Export_Excel" name="b_Export_Excel" type="button" (click)="exportToExcel()"
                                            class="btn btn-outline btn-outline-dashed btn-outline-success btn-sm"><i class="fa fa-file-excel"></i> {{l("ExportToExcel")}}</button>
                                        </div>
                                        </div>
                                    </div>
                                 </div>

                            </div>
                        </div>


                    </form>

                    <div class="row align-items-center">
                        <!--<Primeng-Datatable-Start>-->
                        <div class="primeng-datatable-container col-12"
                        id="multijioparentdiv">


                             <p-table #dataTable [columns]="selectedColumns" [value]="primengTableHelper.records"
                             id="multijiotbldiv" name="tbl_Multi_Geo_Table"
                                [loading]="primengTableHelper.isLoading"
                                (onLazyLoad)="getAllCredentials($event)"
                                rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                [paginator]="false"
                                [lazy]="true"
                                [responsive]="true"
                                [reorderableColumns]="true" [resizableColumns]="true"
                                [loading]="primengTableHelper.isLoading" [(selection)]="selectedMultiGeoRows" selectionMode="multiple">
                                <ng-template pTemplate="header" let-columns let-rowData>
                                    <tr>
                                        <th style="width:30px !important">
                                            <p-tableHeaderCheckbox id="chk_SelectAll" name="chk_SelectAll"></p-tableHeaderCheckbox>
                                        </th>
                                        <th *ngFor="let col of columns;let i = index" pReorderableColumn
                                            pResizableColumn
                                            [pSortableColumn]="primengTableHelper.totalRecordsCount > 0 ? col.header : false"
                                            style="width:{{col.width}};visibility:{{col.visibility}};">
                                            <span style="visibility:{{col.visibility}};">
                                                {{l(col.header)}}
                                            </span>
                                            <p-sortIcon field="{{col.header}}"></p-sortIcon>
                                        </th>
                                        <th style="width:130px !important">{{l('Actions')}}</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-rowIndex1="rowIndex">
                                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex1">
                                        <td style="width:50px !important;">
                                            <p-tableCheckbox [value]="rowData" id="chk_{{rowIndex1}}" name="chk_{{rowIndex1}}" (click)="$event.stopPropagation()"></p-tableCheckbox>
                                        </td>

                                        <td *ngFor="let col of columns;let i = index" class="ui-resizable-column"
                                            style="width:{{col.width}};visibility:{{col.visibility}};">
                                            <span style="visibility:{{col.visibility}};" class="ui-resizable-column">
                                                    {{rowData[col.field]}}
                                            </span>

                                        </td>
                                        <td style="width:100px !important" class="ui-resizable-column">

                                            <div class="btn-group dropdown float-left" dropdown container="body">
                                                <button id="b_Actions_{{rowIndex1}}" name="b_Actions_{{rowIndex1}}" type="button" dropdownToggle class="btn btn-sm">
                                                    <i class="fa fa-ellipsis-h font-action"></i>
                                                </button>
                                                <ul class="dropdown-menu left--200" *dropdownMenu>
                                                    <li>
                                                        <a id="a_Edit" name="a_Edit" href="javascript:;"
                                                            [routerLink]="['/app/main/migrationProcess/multi-geo/edit-multi-geo']"
                                                            [queryParams]="{id: rowData.id}">
                                                            <i class="la la-pencil font-light"></i>
                                                            {{l('Edit')}}
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                </p-table>
                                <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                    {{l('NoData')}}
                                </div>
                                <div class="primeng-paging-container">
                                    <p-paginator id="p_Paginator" name="p_Paginator" [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                                 #paginator
                                                 (onPageChange)="getAllCredentials($event)"
                                                 [totalRecords]="primengTableHelper.totalRecordsCount"
                                                 [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                                    </p-paginator>
                                    <span id="sp_Total_Records_Count" name="sp_Total_Records_Count" class="total-records-count">
                                        {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                                    </span>
                                    <span class="preserve-filter" id="sp_Filter_Applied" name="sp_Filter_Applied" *ngIf="isFilterApplied">
                                        {{"filterapplied" | localize}}
                                  </span>
                                </div>
                        </div>
                        <!--<Primeng-Datatable-End>-->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
