import { Component, OnInit,Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { BreadcrumbService } from '@app/shared/services/breadcrumb.service';
import { Router, ActivatedRoute } from '@angular/router';
import {MigrationProcessServiceProxy, GetCredentialsForEditOutput} from '@shared/service-proxies/service-proxies';
@Component({
  selector: 'app-edit-multi-geo',
  templateUrl: './edit-multi-geo.component.html',
  animations: [appModuleAnimation()]
})
export class EditMultiGeoComponent extends AppComponentBase implements OnInit {
  active = false;
  saving = false;
  loading = false;

  credentials: GetCredentialsForEditOutput = new GetCredentialsForEditOutput();

  constructor(
    injector: Injector,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _breadcrumbService: BreadcrumbService,
    private _migrationProcessServiceProxy: MigrationProcessServiceProxy,
) {
    super(injector);
}


  ngOnInit(): void {
    this.show(this._activatedRoute.snapshot.queryParams['id']);
    this.displayBreadCrumbs();
  }

  show(Id?: number): void {
      this.loading = true;
        this._migrationProcessServiceProxy.getCredentialsForEdit(Id).subscribe(result => {
            this.credentials = result;
        });

    }
    save(): void {
      this.saving = true;
      this._migrationProcessServiceProxy.updateGeoLocation(this.credentials)
          .subscribe(() => {
              this.notify.info(this.l('SavedSuccessfully'));
              this._router.navigate(['/app/main/migrationProcess/multi-geo']);
          });
  }






cancel(): void {
    this._router.navigate(['/app/main/migrationProcess/multi-geo']);
}

  displayBreadCrumbs() {
    const routeUrl = this._router.url;
    this._breadcrumbService.currentRoute(routeUrl);
 }

}
