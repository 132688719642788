<div [@routerTransition]>
    <div class="content">
        <div [class]="containerClass">
            <div class="card card-custom gutter-b">
                <div class="card-header">

                    <div class="card-title">
                        <span  class="card-title">{{l("Edit Geo Location")}}</span>

                    </div>
                </div>
                <form #mpForm="ngForm">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12"></div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">


                            <div class="form-group">
                                <label for="Credential_GeoLocation">{{l("Geo Location")}} * </label>
                                <textarea rows="3" id="Credential_GeoLocation" class="form-control bdr-rad-3" #mpName="ngModel" [(ngModel)]="credentials.geoLocation" name="GeoLocation" minlength="1" maxlength="512" required></textarea>
                                <small class="text-danger"
                                [class.d-none]="mpName.valid || mpName.untouched">Geo Location is
                                required</small>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12"></div>
                    </div>

                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12"></div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 align-right">
                            <button id="b_Cancel" name="b_Cancel" (click)="cancel()" class="btn btn-outline btn-outline-dashed btn-outline-primary bdr-rad-3">
                                <span>{{l("Cancel")}}</span>
                            </button>
                            <button id="b_Save" name="b_Save" (click)="save()" class="btn btn-primary bdr-rad-3"
                            [disabled]="mpForm.form.invalid"   [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                            <i class="fa fa-save"></i><span>{{l("Save")}}</span>
                            </button>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12"></div>
                    </div>
                </div>
                </form>
            </div>
        </div>

    </div>
