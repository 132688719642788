import { PermissionCheckerService, SettingService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private _settingService: SettingService
    ) {}

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                '/assets/common/images/side-bar-icons/Yellow_icons/Dashboard_y.svg',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', '/assets/common/images/side-bar-icons/Yellow_icons/Dashboard_y.svg', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('OnPrem ', '', '/assets/common/images/side-bar-icons/Yellow_icons/onprem_y.svg', '', [], [
                new AppMenuItem('Endpoints', 'Pages.Endpoints', ' /assets/common/images/side-bar-icons/Endpoints.svg', '/app/main/onPrem/endpoints'),
                new AppMenuItem('Modules', 'Pages.Modules', '/assets/common/images/side-bar-icons/Modules.svg', '/app/main/onPrem/modules'),
                new AppMenuItem('Services', 'Pages.OnpremServices', '/assets/common/images/side-bar-icons/Vault-Stores.svg', '/app/main/onPrem/services'),
                //new AppMenuItem('ModulePools', 'Pages.ModulePools', '/assets/common/images/side-bar-icons/Module-Pools.svg', '/app/main/onPrem/modulePools'),
            ]),
            new AppMenuItem('Environments', '', '/assets/common/images/side-bar-icons/Yellow_icons/environments_y.svg', '', [], [
                new AppMenuItem('ActiveDirectory', '', '/assets/common/images/side-bar-icons/Active-Directory.svg', '', [], [
                    new AppMenuItem('Domains', 'Pages.Domains', '/assets/common/images/side-bar-icons/Domains.svg', '/app/main/environments/activedirectory/domains'),
                    new AppMenuItem('DomainUsers', 'Pages.DomainUsers', '/assets/common/images/side-bar-icons/Domain-Users.svg', '/app/main/environments/activedirectory/domainUsers'),
                ]),
                new AppMenuItem('Exchange', '', '/assets/common/images/side-bar-icons/Exchange-Endpoints.svg', '', [], [
                    new AppMenuItem('ExchangeEndpoints', 'Pages.ExchangeEndpoints', '/assets/common/images/side-bar-icons/Exchange-Endpoints.svg', '/app/main/environments/exchange/exchangeEndpoints'),
                    new AppMenuItem('ExchangeServers', 'Pages.ExchangeServers', '/assets/common/images/side-bar-icons/Exchange-servers.svg', '/app/main/environments/exchange/exchangeServers'),
                    new AppMenuItem('ExchangeDatabases', 'Pages.ExchangeDatabases', '/assets/common/images/side-bar-icons/Exchange-databases.svg', '/app/main/environments/exchange/exchangeDatabases'),
                    new AppMenuItem('Mailboxes', 'Pages.Mailboxes', '/assets/common/images/side-bar-icons/Mailboxes.svg', '/app/main/environments/exchange/mailboxes'),
                ]),
                new AppMenuItem('EnterpriseVault', '', '/assets/common/images/side-bar-icons/Enterprise-Vault.svg', '', [], [
                    new AppMenuItem('EVDirectories', 'Pages.EVDirectories', '/assets/common/images/side-bar-icons/EV-Directories.svg', '/app/main/environments/enterpriseVault/evDirectories'),
                    new AppMenuItem('VaultStores', 'Pages.VaultStores', '/assets/common/images/side-bar-icons/Vault-Stores.svg', '/app/main/environments/enterpriseVault/vaultStores'),
                    new AppMenuItem('EVArchives', 'Pages.EVArchives', '/assets/common/images/side-bar-icons/EV-Archives.svg', '/app/main/environments/enterpriseVault/evArchives'),
                    new AppMenuItem('RetentionMapping', 'Pages.RetentionMapping', '/assets/common/images/side-bar-icons/retention-mapping.svg', '/app/main/environments/enterpriseVault/retention-mapping'),
                ], undefined, undefined, () => this._settingService.get('App.SourceConfiguration.EnterpriseVault.IsEnabled')?.toLowerCase() === 'true'),
                new AppMenuItem('SourceOne', '', '/assets/common/images/side-bar-icons/source-one.svg', '', [], [
                    new AppMenuItem('SourceOneEndpoints', 'Pages.SourceOneEndpoints', '/assets/common/images/side-bar-icons/source-one.svg', '/app/main/environments/sourceone/sourceOneEndpoints'),
                    new AppMenuItem('SourceOneArchiveConnections', 'Pages.SourceOneArchiveConnections', '/assets/common/images/side-bar-icons/source-one.svg', '/app/main/environments/sourceone/sourceOneArchiveConnections'),
                    new AppMenuItem('SourceOneArchives', 'Pages.SourceOneArchiveProgress', '/assets/common/images/side-bar-icons/source-one.svg', '/app/main/environments/sourceone/sourceOneArchives'),
                ], undefined, undefined, () => this._settingService.get('App.SourceConfiguration.SourceOne.IsEnabled')?.toLowerCase() === 'true'),
                /*
                new AppMenuItem('QAM', '', '/assets/common/images/side-bar-icons/Exchange.svg', '', [], [
                    new AppMenuItem('QAMEndpoints', 'Pages.QAMEndpoints', '/assets/common/images/side-bar-icons/Exchange-Endpoints.svg', '/app/main/environments/qam/qamEndpoints'),
                    new AppMenuItem('QAMMailStores', 'Pages.QAMMailStores', '/assets/common/images/side-bar-icons/Vault-Stores.svg', '/app/main/environments/qam/qamMailStores'),
                    new AppMenuItem('QAMMailboxes', 'Pages.QAMMailboxes', '/assets/common/images/side-bar-icons/Mailboxes.svg', '/app/main/environments/qam/qamMailboxes'),
                ]),
               new AppMenuItem('MLX', '', '/assets/common/images/side-bar-icons/Exchange.svg', '', [], [
                    new AppMenuItem('MLXEndpoints', 'Pages.MLXEndpoints', '/assets/common/images/side-bar-icons/Exchange-Endpoints.svg', '/app/main/environments/mlx/mlxEndpoints'),
                    new AppMenuItem('MLXServers', 'Pages.MLXServers', '/assets/common/images/side-bar-icons/Exchange-servers.svg', '/app/main/environments/mlx/mlxServers'),
                    new AppMenuItem('MLXMailboxes', 'Pages.MLXMailboxes', '/assets/common/images/side-bar-icons/Mailboxes.svg', '/app/main/environments/mlx/mlxMailboxes'),
                ]), */

                // new AppMenuItem('FileSystem', '', '/assets/common/images/side-bar-icons/File-system.svg', '', [], [
                //     new AppMenuItem('FileShares', 'Pages.FileShares', '/assets/common/images/side-bar-icons/File-share.svg', '/app/main/fileSystem/fileShares'),
                // ]),
                // new AppMenuItem('Sharepoint', 'Pages.Sharepoint', '/assets/common/images/side-bar-icons/Sharepoint.svg', '/app/main/share-point', [

                // ]
                // ),
                // new AppMenuItem('OneDrive', 'Pages.OneDrive', '/assets/common/images/side-bar-icons/OneDrive.svg', '/app/main/environments/one-drive', []),
                // new AppMenuItem('PST', '', '/assets/common/images/side-bar-icons/PST.svg', '', [], [
                //     new AppMenuItem('PstOutputs', 'Pages.PstOutputs', '/assets/common/images/side-bar-icons/PST-outputs.svg', '/app/main/pst/pstOutputs'),
                //     new AppMenuItem('PstFiles', 'Pages.PstFiles', '/assets/common/images/side-bar-icons/PST-files.svg', '/app/main/pst/pstFiles'),
                // ]),
                new AppMenuItem('StagingCaches', 'Pages.StagingCaches', '/assets/common/images/side-bar-icons/staging-cache.svg', '/app/main/environments/stagingCaches', []),
                ]),
            new AppMenuItem('Workflows', '', '/assets/common/images/side-bar-icons/Yellow_icons/migration_process_y.svg', '', [], [
                new AppMenuItem('Migration Template', 'Pages.MigrationProcess', '/assets/common/images/side-bar-icons/migration-template.svg', '/app/main/workflows/migrationTemplate'),
                // new AppMenuItem('StagingCaches', 'Pages.StagingCaches', '/assets/common/images/side-bar-icons/staging-cache.svg', '/app/main/environments/stagingCaches'),
                new AppMenuItem('EmailTemplates', 'Pages.EmailTemplates', '/assets/common/images/side-bar-icons/email-template.svg', '/app/main/workflows/emailTemplates'),
                //new AppMenuItem('Multi Geo', 'Pages.MigrationProcess', '/assets/common/images/side-bar-icons/Globe_font_awesome.svg', '/app/main/migrationProcess/multi-geo'),
            ]),

            // new AppMenuItem('Migration', 'Pages.MigrationProcess', '/assets/common/images/side-bar-icons/Yellow_icons/migration_y.svg', '', [], [
            //     new AppMenuItem('MigrationMapping', '', '/assets/common/images/side-bar-icons/migration-mapping.svg', '/app/main/mappings/enterprisevault/mail-archive-mapping'),
            //     new AppMenuItem('MigrationProgress', '', '/assets/common/images/side-bar-icons/migration-progress.svg', '/app/main/migrationprogress/enterprisevault/migration-progress'),
            //     new AppMenuItem('Leavers Mapping', '', '/assets/common/images/side-bar-icons/migration-mapping.svg', '/app/main/mappings/enterprisevault/leavers-mapping'),
            //     new AppMenuItem('Finished Migrations', 'Pages.MailArchivesFinished', '/assets/common/images/side-bar-icons/MailArchivesFinished.svg', '/app/main/reports/enterprisevault/mailArchivesFinished'),


            // ]),
            new AppMenuItem('Mappings', '', '/assets/common/images/side-bar-icons/Yellow_icons/migration_y.svg', '', [], [
                new AppMenuItem('Onboard Mappings', 'Pages.OnboardMappings', '/assets/common/images/side-bar-icons/Onboard-Mappings.svg', '/app/main/mappings/onboarding-mapping'),
                new AppMenuItem('EnterpriseVault', '', '/assets/common/images/side-bar-icons/Enterprise-Vault.svg', '', [], [
                    new AppMenuItem('Mail Archive Mapping', 'Pages.MigrationMapping', '/assets/common/images/side-bar-icons/migration-mapping.svg', '/app/main/mappings/enterprisevault/mail-archive-mapping'),
                    new AppMenuItem('Leavers Mapping', 'Pages.LeaversMapping', '/assets/common/images/side-bar-icons/Leaver-Icon.svg', '/app/main/mappings/enterprisevault/leavers-mapping'),
                    new AppMenuItem('Journal Mapping', 'Pages.JournalMapping', '/assets/common/images/side-bar-icons/Journal-Icon.svg', '/app/main/mappings/enterprisevault/journal-mapping'),
                    new AppMenuItem('Shared Archive Mapping', 'Pages.SharedArchiveMapping', '/assets/common/images/side-bar-icons/migration-mapping.svg', '/app/main/mappings/enterprisevault/shared-archive-mapping'),
                ], undefined, undefined, () => this._settingService.get('App.SourceConfiguration.EnterpriseVault.IsEnabled')?.toLowerCase() === 'true'),
                // Added SourceOne Mappings
                new AppMenuItem('SourceOne', '', '/assets/common/images/side-bar-icons/source-one.svg', '', [], [
                    new AppMenuItem('Archive Mapping', 'Pages.SourceOneUserMailboxMappingSelection', '/assets/common/images/side-bar-icons/migration-mapping.svg', '/app/main/mappings/sourceone/source-one-migration/source-one-migration'),
                    new AppMenuItem('Leavers Mapping', 'Pages.LeaversMapping', '/assets/common/images/side-bar-icons/Leaver-Icon.svg', '/app/main/mappings/sourceone/source-one-leavers-mapping'),
                    new AppMenuItem('Journal Mapping', 'Pages.SourceOneJournalMapping', '/assets/common/images/side-bar-icons/Journal-Icon.svg', '/app/main/mappings/sourceone/source-one-journal-mapping'),
                ], undefined, undefined, () => this._settingService.get('App.SourceConfiguration.SourceOne.IsEnabled')?.toLowerCase() === 'true'),
                /*
                new AppMenuItem('Quest Archive Manager', '', '/assets/common/images/side-bar-icons/migration-mapping.svg', '', [], [
                    new AppMenuItem('QAM Mapping', 'Pages.QAMUserMailboxMappingSelection', '/assets/common/images/side-bar-icons/migration-mapping.svg', '/app/main/qamMigrations/qamUserMailboxMappingSelection'),
                ]),
                new AppMenuItem('Metalogix', '', '/assets/common/images/side-bar-icons/migration-mapping.svg', '', [], [
                    new AppMenuItem('MLXUserMailboxMappingSelection', 'Pages.MLXUserMailboxMappingSelection', '/assets/common/images/side-bar-icons/migration-mapping.svg', '/app/main/mlxMigrations/mlxUserMailboxMappingSelection'),
                ]),*/
            ]),
            new AppMenuItem('MigrationProgress', '', '/assets/common/images/side-bar-icons/Yellow_icons/migration_progress_y.svg', '', [], [
                new AppMenuItem('Overview', 'Pages.Overview', '/assets/common/images/side-bar-icons/overview.svg', '/app/main/migrationprogress/overview/progress-by-tags'),
                new AppMenuItem('Onboard Progress', '', '/assets/common/images/side-bar-icons/onboarding-progress.svg', '/app/main/migrationprogress/onboard-progress'),
                new AppMenuItem('EnterpriseVault', '', '/assets/common/images/side-bar-icons/Enterprise-Vault.svg', '', [], [
                    new AppMenuItem('Workflow Progress', 'Pages.MigrationProgress', '/assets/common/images/side-bar-icons/onboarding-progress.svg', '/app/main/migrationprogress/enterprisevault/migration-progress'),
                    new AppMenuItem('Archive Progress', 'Pages.ArchiveProgress', '/assets/common/images/side-bar-icons/Enterprise-Vault.svg', '/app/main/migrationprogress/enterprisevault/archive-progress'),
                    new AppMenuItem('Journal Progress', 'Pages.JournalProgress', '/assets/common/images/side-bar-icons/Journal-Icon.svg', '/app/main/migrationprogress/enterprisevault/journal-progress')
                ], undefined, undefined, () => this._settingService.get('App.SourceConfiguration.EnterpriseVault.IsEnabled')?.toLowerCase() === 'true'),
                new AppMenuItem('SourceOne', '', '/assets/common/images/side-bar-icons/source-one.svg', '', [], [
                    new AppMenuItem('Workflow Progress', 'Pages.SourceOneMigrationProgresses', '/assets/common/images/side-bar-icons/onboarding-progress.svg', '/app/main/migrationprogress/sourceone/source-one-progress'),
                    new AppMenuItem('Archive Progress', 'Pages.SourceOneArchiveProgress', '/assets/common/images/side-bar-icons/Journal-Icon.svg', '/app/main/migrationprogress/s1-archive-progress'),
                    new AppMenuItem('Journal Progress', 'Pages.SourceOneJournalProgress', '/assets/common/images/side-bar-icons/Journal-Icon.svg', '/app/main/migrationprogress/sourceone/source-one-journal-progress'),
                    // new AppMenuItem('Finished Mail Archives', 'Pages.MailArchivesFinished', '/assets/common/images/side-bar-icons/MailArchivesFinished.svg', '/app/main/migrationProcess/mailArchivesFinished'),
                    // new AppMenuItem('Finished Journals', '', '/assets/common/images/side-bar-icons/File-system.svg', '/app/main/migrationProcess/finished-journals'),
                ], undefined, undefined, () => this._settingService.get('App.SourceConfiguration.SourceOne.IsEnabled')?.toLowerCase() === 'true'),
                // new AppMenuItem('QAMMigrationProgresses', 'Pages.QAMMigrationProgresses', '/assets/common/images/side-bar-icons/onboarding-progress.svg', '/app/main/evMigrations/qamMigrationProgresses'),
                // new AppMenuItem('MLXMigrationProgresses', 'Pages.MLXMigrationProgresses', '/assets/common/images/side-bar-icons/onboarding-progress.svg', '/app/main/evMigrations/mlxMigrationProgresses')
            ]),
            // new AppMenuItem('Wizards', '', '/assets/common/images/side-bar-icons/wizard.svg', '', [

            // ]),

            new AppMenuItem('Reports and Auditing', '', '/assets/common/images/side-bar-icons/Yellow_icons/analytics.svg', '', [], [
                new AppMenuItem('FinishedOnboard', '', '/assets/common/images/side-bar-icons/MailArchivesFinished.svg', '/app/main/reports/finished-onboard'),
                new AppMenuItem('EnterpriseVault', '', '/assets/common/images/side-bar-icons/Enterprise-Vault.svg', '', [], [
                    new AppMenuItem('Failed Item Management', 'Pages.FailedItemsSummary', '/assets/common/images/side-bar-icons/Enterprise-Vault.svg', '/app/main/reports/enterprisevault/failed-item-management'),
                    new AppMenuItem('Finished Workflows', 'Pages.MailArchivesFinished', '/assets/common/images/side-bar-icons/MailArchivesFinished.svg', '/app/main/reports/enterprisevault/mailArchivesFinished'),
                    new AppMenuItem('Finished Journals', 'Pages.FinishedJournals', '/assets/common/images/side-bar-icons/Journal-Icon.svg', '/app/main/reports/enterprisevault/finished-journals')
                ], undefined, undefined, () => this._settingService.get('App.SourceConfiguration.EnterpriseVault.IsEnabled')?.toLowerCase() === 'true'),
                new AppMenuItem('SourceOne', '', '/assets/common/images/side-bar-icons/source-one.svg', '', [], [
                    new AppMenuItem('Finished Workflows', 'Pages.SourceOneUserMailboxFinished', '/assets/common/images/side-bar-icons/MailArchivesFinished.svg', '/app/main/reports/sourceone/source-one-finished'),
                    new AppMenuItem('Finished Journals', 'Pages.SourceOneFinishedJournals', '/assets/common/images/side-bar-icons/Journal-Icon.svg', '/app/main/reports/sourceone/finished-source-one-journals')
                ], undefined, undefined, () => this._settingService.get('App.SourceConfiguration.SourceOne.IsEnabled')?.toLowerCase() === 'true'),
               // new AppMenuItem('QAMUserMailboxFinished', 'Pages.QAMUserMailboxFinished', '/assets/common/images/side-bar-icons/MailArchivesFinished.svg', '/app/main/migrationProcess/qamUserMailboxFinished'),
              //  new AppMenuItem('MLXUserMailboxFinished', 'Pages.MLXUserMailboxFinished', '/assets/common/images/side-bar-icons/MailArchivesFinished.svg', '/app/main/migrationProcess/mlxUserMailboxFinished'),
                new AppMenuItem('Mailbox Migration Report', 'Pages.MigrationProcess', '/assets/common/images/side-bar-icons/migration-template.svg', '/app/main/reports/mailbox-migration-report'),
                new AppMenuItem('OneDrive Reports', 'Pages.OneDriveFinished', '/assets/common/images/side-bar-icons/OneDrive.svg', '/app/main/reports/one-drive-finished'),
            ]),

            new AppMenuItem(
                'Administration',
                '',
                '/assets/common/images/side-bar-icons/Yellow_icons/Administration_y.svg',
                '',
                [],
                [
                    //new AppMenuItem(
                    //    'OrganizationUnits',
                    //    'Pages.Administration.OrganizationUnits',
                    //    'flaticon-map',
                    //    '/app/admin/organization-units'
                    //),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    //new AppMenuItem(
                    //    'Languages',
                    //    'Pages.Administration.Languages',
                    //    'flaticon-tabs',
                    //    '/app/admin/languages',
                    //    ['/app/admin/languages/{name}/texts']
                    //),
                    //new AppMenuItem(
                    //    'AuditLogs',
                    //    'Pages.Administration.AuditLogs',
                    //    'flaticon-folder-1',
                    //    '/app/admin/auditLogs'
                    //),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'flaticon-lock',
                        '/app/admin/maintenance'
                    ),
                    //new AppMenuItem(
                    //    'Subscription',
                    //    'Pages.Administration.Tenant.SubscriptionManagement',
                    //    'flaticon-refresh',
                    //    '/app/admin/subscription-management'
                    //),
                    //new AppMenuItem(
                    //    'VisualSettings',
                    //    'Pages.Administration.UiCustomization',
                    //    'flaticon-medical',
                    //    '/app/admin/ui-customization'
                    //),
                    //new AppMenuItem(
                    //    'WebhookSubscriptions',
                    //    'Pages.Administration.WebhookSubscription',
                    //    'flaticon2-world',
                    //    '/app/admin/webhook-subscriptions'
                    //),
                    //new AppMenuItem(
                    //    'DynamicProperties',
                    //    'Pages.Administration.DynamicProperties',
                    //    'flaticon-interface-8',
                    //    '/app/admin/dynamic-property'
                    //),
                    new AppMenuItem(
                        'Notifications',
                        '',
                        'flaticon-alarm',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Inbox',
                                '',
                                'flaticon-mail-1',
                                '/app/notifications'
                            ),
                            new AppMenuItem(
                                'MassNotifications',
                                'Pages.Administration.MassNotification',
                                'flaticon-paper-plane',
                                '/app/admin/mass-notifications'
                            )
                        ]
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'flaticon-settings',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'flaticon-settings',
                        '/app/admin/tenantSettings'
                    )
                ])
            //  new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', '/assets/common/images/side-bar-icons/demo-Ui-compo.svg', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
