import { Component, OnInit,Injector,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from '@app/shared/services/breadcrumb.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import {MigrationProcessServiceProxy ,CredentialsDto, ProgressServiceProxy } from '@shared/service-proxies/service-proxies';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { FileDownloadService } from '@shared/utils/file-download.service';
@Component({
  selector: 'app-multi-geo',
  templateUrl: './multi-geo.component.html',
  styleUrls: ['./multi-geo.component.css'],
  animations: [appModuleAnimation()]
})
export class MultiGeoComponent extends AppComponentBase implements OnInit, AfterViewInit {

  @ViewChild('paginator', { static: true }) paginator: Paginator;
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('searchTextInput') searchTextInputField: ElementRef;
  allCredentials: CredentialsDto[];
  selectedColumns = [];
  columns = [];
  resultData = [];
  selectedMultiGeoRows: any[]=[];
  filterText = '';
  isFilterApplied = false;


  loading = false;
  checkboxSelectFilter: string=undefined;
  constructor(
    injector: Injector,
    private _breadcrumbService: BreadcrumbService,
    private _router: Router,
    private _progressServiceProxy: ProgressServiceProxy,
    private _migrationProcessServiceProxy: MigrationProcessServiceProxy,
    private _fileDownloadService: FileDownloadService,

) {
    super(injector);
}

  ngOnInit(): void {
    this.displayBreadCrumbs();

    this.columns = [
      { field: 'id', header: 'Id', width: '100px', display: 'table-cell', visibility:true },
      { field: 'credentialName', header: 'CredentialName', width: '250px', display: 'table-cell',visibility:true },
      { field: 'credentialType', header: 'CredentialType', width: '250px', display: 'table-cell',visibility:true },
      { field: 'geoLocation', header: 'GeoLocation', width: '250px', display: 'table-cell' ,visibility:true},


  ];
  this.selectedColumns = this.columns;

    this.getAllCredentials();
  }
  ngAfterViewInit() {
    setTimeout(() => {
        this.searchTextInputField?.nativeElement?.focus();
    }, 300);
}
  displayBreadCrumbs() {
    const routeUrl = this._router.url;
    this._breadcrumbService.currentRoute(routeUrl);
}
exportToExcel(): void {
  this.checkboxSelectFilter = '';
  let filter = '';
  this.selectedMultiGeoRows.forEach(x => {
    filter += x.id + ',';
  });

  if (filter != '') {
    this.checkboxSelectFilter = filter;
  }
  this.showloader();
  this._migrationProcessServiceProxy.getCredentialToExcel(this.primengTableHelper.getSorting(this.dataTable), this.filterText,this.checkboxSelectFilter)
  .subscribe(result => {
    if(result.fileName === null) {
        this.hideloader();
        const msg = 'An error occurred while exporting excel file.';
        this.message.info(msg, '', { isHtml: true });
        return;
    } else{
        this._fileDownloadService.downloadTempFile(result);
        this.hideloader();
    }
 });
}
getAllCredentials(event?: LazyLoadEvent) {

  if (this.primengTableHelper.shouldResetPaging(event)) {
      this.paginator.changePage(0);
      return;
  }
  this.loading = true;
  this.resultData = [];
  this.primengTableHelper.showLoadingIndicator();
  this._progressServiceProxy.getAllCredentials(this.primengTableHelper.getSorting(this.dataTable), this.filterText).subscribe(result => {
     this.primengTableHelper.totalRecordsCount = result.length;
     this.primengTableHelper.records = result;
     this.loading = false;
     this.primengTableHelper.hideLoadingIndicator();
  });
}


onKeypress(event) {

  this.getAllCredentials(event);
  if(this.filterText)
  this.isFilterApplied=true;
  else
  this.isFilterApplied=false;
  this.paginator.changePageToFirst(event);
}
showloader() {
    this.primengTableHelper.showLoadingIndicator();
}
hideloader() {
    this.primengTableHelper.hideLoadingIndicator();
}
}
